import { link, urlADM, tokenLink } from "../../services/variables";

const jsonButtons = [
  { name: `Inicio`, link: `/`, img: `Inicio` },
  {
    name: `Coronavírus (Covid-19)`,
    link: `${link}/portaltm/covid19/index.xhtml?token=${tokenLink}`,
    img: `Coronavírus (Covid-19)`,
  },
  {
    name: `Fale Conosco`,
    link: `/detalhe/fale-conosco-atendimento`,
    img: `telephone`,
  },
  { name: `Notícias`, link: `/noticias`, img: `Notícias` },
  {
    name: `e-SIC`,
    link: `${urlADM}/e-sic?token=${tokenLink}`,
    img: `e-SIC`,
  },

  {
    name: `Perguntas e Respostas`,
    link: `${urlADM}/e-sic/perguntas-e-respostas?token=${tokenLink}`,
    img: `Perguntas_e_Respostas`,
  },
];

export default jsonButtons;
